.row {
  input[type="text"] {
    height: 100%;
    min-width: 200px;
    width: 100%;
    max-width: 400px;
    height: 40px;
    padding-inline: 16px;
    border-radius: 50px;
    border: 2px solid #333;
    outline: none;
    &:focus {
      border-color: var(--main);
    }
  }
  .actions {
    white-space: nowrap;
    width: 0;
    button {
      margin-inline: 6px;
      svg {
        width: 28px;
      }
      &:hover {
        svg {
          color: #000;
        }
      }
      &:disabled {
        cursor: default;
        svg {
          color: #999;
        }
      }
    }
    .success {
      svg {
        color: green;
      }
    }
    .edit {
      svg {
        color: var(--main);
      }
    }
    .delete {
      svg {
        color: red;
      }
    }
  }
}
