.notify {
  position: fixed;
  bottom: 20px;
  right: 20px;
  border-radius: 50px;
  padding: 16px 24px;
  z-index: 9999;
  color: transparent;
  display: flex;
  gap: 24px;
  background-color: transparent;
  transition: all 2s;
  transition-delay: 0.5s;

  button {
    svg {
      width: 32px;
    }
  }
  p {
    display: flex;
    align-items: center;
    font-size: 1.2rem;
    font-weight: bold;
  }

  &.active {
    color: white;
    background-color: black;
    box-shadow: 0 0 10px 3px rgba($color: #000000, $alpha: 0.3);
    transition: all 0s;
    transition-delay: 0s;
    &.success {
      background-color: green;
    }
    &.warning {
      color: black;
      background-color: gold;
    }
    &.error {
      background-color: red;
    }
  }
}
