$period: 2.5s;
.loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 4px;
  background-color: rgba($color: red, $alpha: 0.25);
  z-index: 100;
  &::after,
  &::before {
    content: "";
    position: absolute;
    z-index: 101;
    width: 25%;
    height: 100%;
    transform: translateX(-100%);
    background-color: red;
    animation: loading infinite $period;
  }
  &::after {
    animation-delay: calc($period / 4);
  }
}

@keyframes loading {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100vw);
  }
}
