.header {
  z-index: 99;
  background-color: #fff;
  box-shadow: 0 0 10px rgba($color: #000000, $alpha: 0.3);
  position: sticky;
  top: 0;
  overflow: hidden;
  > nav {
    > ul {
      list-style: none;
      display: flex;
      gap: 8px;
      > li {
        display: flex;
        > a {
          text-decoration: none;
          color: black;
          padding: 16px 24px;
          transition: background-color 0.3s;
          &:hover {
            background-color: orange;
          }
        }
      }
    }
  }
}
