.footer {
  // background-color: #001020;
  background-color: #000;
  .container {
    display: flex;
    flex-direction: column;
    // flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding-block: 32px;
    span {
      padding: 4px;
      color: white;
    }
  }
}
