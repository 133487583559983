.home {
  display: flex;
  flex-direction: column;
  justify-content: center;
  .container {
    flex-grow: 1;

    .books__grid {
      padding: 16px;
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
      gap: 16px;
      .card {
        background-color: #fff;
        border-radius: 10px;
        box-shadow: 0 0 10px rgba($color: #000000, $alpha: 0.2);
        padding: 24px 16px;
        transition: transform 0.3s;
        &:hover {
          transform: scale(1.04);
        }
        .card__img {
          margin: 16px;
          overflow: hidden;
          aspect-ratio: 1/1;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center;
          }
        }
        h3 {
          margin-block-end: 8px;
        }
      }
    }
  }
  .no__data {
    justify-self: center;
    align-self: center;
  }
}
