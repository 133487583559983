.panel {
  display: flex;
  flex-direction: column;
  .form {
    padding-inline: 2vw;
    padding-block: 32px;
    // display: flex;
    // justify-content: space-between;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 16px;
    input {
      padding: 8px 16px;
      border-radius: 50px;
      border: 2px solid #333;
      outline: none;
      &:focus {
        border-color: var(--main);
      }
    }

    .form__actions {
      display: flex;
      justify-content: flex-start;
      gap: 16px;
      .form__submit {
        background-color: green;
      }
      .form__clear {
        background-color: red;
      }
    }
  }
  .table__container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 16px;
    .table__actions {
      padding-inline: 2vw;
      display: flex;
      justify-content: flex-end;
      gap: 16px;
      .table__delete__selected {
        background-color: red;
      }
    }
    .table {
      overflow-x: auto;
      width: 100%;
      display: flex;
      table {
        padding-inline: 2vw;
        padding-block-end: 32px;
        // min-width: 100%;
        overflow: hidden;
        flex-grow: 1;
        text-align: center;
        // white-space: nowrap;
        input[type="checkbox"] {
          width: 18px;
          height: 18px;
          cursor: pointer;
          filter: hue-rotate(150deg);
          &:disabled {
            cursor: default;
          }
        }
        thead {
          color: white;
          background-color: var(--main);
          tr {
            > :first-child {
              width: 0;
            }
            th {
              padding: 12px;
            }
          }
        }
        tbody {
          > :nth-child(even) {
            background-color: #fff;
          }
          tr {
            td {
              padding: 6px 12px;
            }
            &:hover {
              background-color: #cfcfcf;
            }
          }
        }
      }
    }
  }
}
